<template>
  <div class="row">
    <div class="column content-box">
        <section >
            <div class="columns meduim-12" style="height:0px;">
                <div class="row info_field">
                    <woot-button
                        color-scheme="success"
                        class-names="button--fixed-right-top"
                        icon="add-circle"
                        :disabled="isAddItemDisabled"
                        @click="openAddItemModal"
                    >
                       {{$t('ORDER.DETAILS.ADD_ITEM_BTN')}}
                    </woot-button>
                </div>
            </div>
            <div class="columns meduim-12">
                <div class="row info_field">
                    <div class="columns medium-6">
                        <div class="row">
                            <p class="columns medium-2"><b>{{$t('ORDER.DETAILS.INFO.NAME')}}</b></p>
                            <p class="columns medium-10">
                                <!-- {{order.contact.name}} -->
                                {{contactName}}
                                <router-link v-if="orderConversationLink" :to="orderConversationLink">
                                    <woot-button
                                        size="small"
                                        variant="clear"
                                        color-scheme="primary"
                                        icon="chat"
                                    >
                                    </woot-button>
                                </router-link>
                            </p>
                        </div>
                    </div>
                    <div class="columns medium-6">
                        <div class="row">
                            <p class="columns medium-2"><b>{{$t('ORDER.DETAILS.INFO.PHONE')}}</b></p>
                            <p class="columns medium-10">{{contactPhoneNumber}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns meduim-12">
                <div class="row info_field">
                    <div class="columns medium-6">
                        <div class="row">
                            <div class="columns medium-2">
                                <p class="margin-top"><b>{{$t('ORDER.DETAILS.INFO.PAID')}}</b></p>
                            </div>
                            <div class="columns medium-4">
                                <input v-model="order.paid_total" type="number" />
                            </div>
                            <div class="columns medium-6"></div>
                        </div>
                    </div>
                    <div class="columns medium-6">
                        <div class="row info_field">
                            <p class="columns medium-2 margin-bottom"><b>{{$t('ORDER.DETAILS.INFO.CURRENCY')}}</b></p>
                            <div class="columns medium-10">
                                <div class="status_select_wrapper">
                                    <multiselect
                                        v-model="selectedCurrency"
                                        :options="CURRENCY_LIST"
                                        class="small-12"
                                        track-by="id"
                                        label="title"
                                        :multiple="false"
                                        :close-on-select="true"
                                        :clear-on-select="false"
                                        :hide-selected="true"
                                        placeholder="Pick Order Status"
                                        selected-label
                                        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                        @select="updateCurrency($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns meduim-12">
                <div class="row info_field">
                    <div class="columns medium-6">
                        <div class="row">
                            <p class="columns medium-2"><b>{{$t('ORDER.DETAILS.INFO.ORDER_TIME')}}</b></p>
                            <p v-if="order.created_at" class="columns medium-10">{{new Date(order.created_at).toGMTString().slice(0,-7)}}</p>
                        </div>
                    </div>
                    <div class="columns medium-6">
                        <div class="row info_field">
                            <p class="columns medium-2 margin-bottom"><b>{{$t('ORDER.DETAILS.INFO.STATUS')}}</b></p>
                            <div class="columns medium-10">
                                <div class="status_select_wrapper">
                                    <multiselect
                                        v-model="order.order_status"
                                        :options="ORDER_STATUSES"
                                        class="small-12"
                                        track-by="id"
                                        label="title"
                                        :multiple="false"
                                        :close-on-select="true"
                                        :clear-on-select="false"
                                        :hide-selected="true"
                                        placeholder="Pick Order Status"
                                        selected-label
                                        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                                        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
                                        @select="updateOrderStatus($event)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        <section class="padding-top">
            <h5>Otder Items</h5>
            <OrderItemsTable
                :order_items="order.order_items"
                :cols="cols"
                :merchant="merchant"
                :order="order"
                :isLoading="isOrderLoading"
                @on-sort-change="onSortChange"
                @onQuantityChange="onQuantityChange"
            />
        </section>


        <div>
            <woot-button
                size="small"
                color-scheme="primary"
                class="button--fixed-left-top"
                :isLoading="isUpdating"
                :isDisabled="isUpdating"
                @click="updateOrderDetails"
            >
                Update
            </woot-button>
        </div>

        <div v-if="showAddItemModal">
            <AddItemModal
            :show="showAddItemModal"
            :on-close="closeAddItemModal"
            :order="order"
            :categories="categories"
            :catalogueItems="catalogueItems"
            />
        </div>


    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderAPI from "../../../../api/orders";
import MerchantAPI from "../../../../api/merchant";
import alertMixin from 'shared/mixins/alertMixin';
import {ORDER_STATUSES,CURRENCY_LIST} from "./order_constant"
import Button from '../../../../components/buttons/Button.vue';
import AddItemModal from './AddItemModal.vue';
import OrderItemsTable from './OrderItemsTable.vue';
import ContactApi from '../../../../api/contacts';
import CatalogAPI from "../../../../api/catalogue/catalog";

export default {
    components:{
        Button,
        AddItemModal,
        OrderItemsTable,
    },
    mixins: [alertMixin],
    data(){
        return{
            isOrderLoading:false,
            order: {order_items:[],contact:{}},
            ORDER_STATUSES,
            CURRENCY_LIST,
            selectedCurrency:{},
            showAddItemModal: false,
            cols: [
                { key: 'item_name', name: 'Name', visible: true, type: 'default' },
                { key: 'currency', name: 'Currency', visible: true, type: 'default' },
                { key: 'quantity', name: 'Quantity', visible: true, type: 'default' },
                { key: 'item_price', name: 'Price', visible: true, type: 'default' },
                { key: 'total', name: 'Total', visible: true, type: 'default' },
                { key: 'action', name: 'Action', visible: true, type: 'default' },
            ],
            isUpdating: false,
            merchant:{},
            catalog:{},
            display_conversation_id: null,
        }
    },
    computed:{
        ...mapGetters({
            currentUser: 'getCurrentUser',
            categories: "catalog/getCatagories",
            catalogueItems: "catalog/getCatalogItems",
        }),
        orderConversationLink(){
            return `/app/accounts/${this.currentUser.account_id}/conversations/${this.display_conversation_id}`
        },
        contactName(){
            return this.order?.contact?.name || "";
        },
        contactPhoneNumber(){
            return this.order?.contact?.phone_number || "";
        },
        isAddItemDisabled(){
            const enableStatuslList = ['fresh','pending','packed','paid'];
            const isDisable = !enableStatuslList.includes(this.order?.order_status?.id);
            return isDisable;
        },
        // end computed
    },
    mounted(){
        this.getOrderById(this.$route.params.order_id);
        this.getCatalog();
        this.$store.dispatch("catalog/getCatagories");
        this.$store.dispatch("catalog/getCatalogProductItems");
    },
    watch: {
        currentUser: {
            handler(newValue, oldValue) {
                this.getMerchant();
            },
            immediate: true
        },
        '$route.params.order_id': {
            handler(newOrderId, oldOrderId) {
                if (newOrderId) {
                    this.getOrderById(newOrderId);
                }
            },
            immediate: true
        }
    },
    methods:{
        async getOrderById(order_id){
            this.isOrderLoading = true;
            try {
                const {data} = await OrderAPI.getOrderById(order_id);
                this.order = data;
                // format status for option way
                this.order.order_status = ORDER_STATUSES.find(el=>el.id === this.order.order_status) || {}
                this.selectedCurrency = CURRENCY_LIST.find(el=>el.id === this.order.currency) || {}
                // set existItem = true at the beginning to keep separate the newly added items to remove id from payload
                this.order.order_items = this.order.order_items.map(el=>({...el,isExistItem:true}) )

                // get conversation id
                this.getConversationDisplayId();
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.FETCH_ORDER_FAIL'));
            }finally{
                this.isOrderLoading = false;
            }
        },
        async getMerchant(){
            try {
                if (!this.currentUser.accounts?.length) {
                    return
                }
                const merchantName = this.currentUser.accounts[0]?.name;
                if (merchantName) {
                    const {data} = await MerchantAPI.getMerchantByName(merchantName);
                    this.merchant = data;
                }
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.FETCH_MERCHANT_FAIL'));
            }
        },
        async updateOrderStatus(selectedOption){
            // console.log({selectedOption,order:this.order});
            try {
                const payload = {order_status:selectedOption.status_code}
                const {data} = await OrderAPI.updateOrderById(this.order.id,payload)
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_SUCCESS'));
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_FAIL'));
            }
        },
        async updateCurrency(selectedOption){
            this.order.currency = selectedOption.id;
        },
        openAddItemModal(){
            this.showAddItemModal = true;
        },
        closeAddItemModal(){
            this.showAddItemModal = false;
        },
        onSortChange(params){
            // console.log({params});
        },
        onQuantityChange(e,rowData){
            // console.log("Got in parent quantity",e,rowData);
            const quantity = parseInt(e.target.value||"0");
            const index = this.order.order_items.findIndex(item=>item.id ===rowData.id);
            if (index > -1) {
                this.order.order_items[index].quantity = quantity
            }

        },
        async updateOrderDetails(){
            // console.log("Update order = ",this.order);
            const updateOrderPayload = {
                currency : this.order.currency,
                paid_total: this.order.paid_total,
                order_items: this.order.order_items
                    .filter(el=>el.quantity > 0)
                    .map(({id,item_price,quantity,catalog_item_id,isExistItem})=>({
                        ...(isExistItem ? {id}:{}),
                        item_price,
                        quantity,
                        catalog_item_id
                    })),
            }

             try {
                this.isUpdating = true;
                const result = await OrderAPI.updateOrderById(this.order.id,updateOrderPayload)
                 this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_SUCCESS'));
                this.isUpdating = false;
            } catch (error) {
                console.log(error);
                this.showAlert(this.$t('ORDER.DETAILS.ALERT_MSG.UPDATE_FAIL'));
                this.isUpdating = false;
            }
        },
        async getCatalog(){
            try {
                const {data} = await CatalogAPI.getDefaultCatalog();
                if (!data) {
                    return;
                }
                this.catalog = data;
                this.getConversationDisplayId();
            } catch (error) {
                console.log("getCatalog = ",error);
                this.showAlert('error to get catalog');
            }
        },
        async getConversationDisplayId(){
            if (!this.catalog?.inbox_id || !this.order?.contact?.id) {
                return
            }

            try {
                const {data} = await ContactApi.getShopContactConversationUrl({
                    contact_id: this.order?.contact?.id,
                    inbox_id: this.catalog.inbox_id,
                })
                // console.log(data,"Response",this.currentUser);
                if (data?.length) {
                    this.display_conversation_id = data[0];
                }
            } catch (error) {
                console.log("getCatalog = ",error);
                this.showAlert('error to get catalog');
            }
        },
        /*
        async onChatStart(order){
            console.log("onChatStart",order,this.catalog);
            try {
                const {data} = await ContactApi.getShopContactConversationUrl({
                    contact_id: order.contact.id,
                    inbox_id: this.catalog.inbox_id,
                })
                console.log(data,"Response",this.currentUser);
                if (data?.length) {
                    this.$router.push(`/app/accounts/${this.currentUser.account_id}/conversations/${data[0]}`)
                }
                // https://chat.peasy.ai/app/accounts/{{account_id}}/conversations/{{display_id}}
            } catch (error) {
                console.log(error);
            }
        },
        */

        // end method
    },

}
</script>

<style>
.status_select_wrapper{
    max-width: 200px;
}
.info_field{
    height: 60px;
    align-items: center;
}
.margin-bottom{
    margin-bottom: var(--space-small);
}
.margin-top{
    margin-top: var(--space-small);
}
</style>