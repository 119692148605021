<template>
  <section class="orderItems_table_wrap">
      <!-- scroll-width="120rem" -->
    <ve-table
      :fixed-header="true"
      max-height="100%"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
    />

    <div>
      <div v-if="isLoading" class="loader_and_empty">
        <spinner />
        <span>{{ 'Order is loading' }}</span>
      </div>
      <empty-state
        v-else-if="!isLoading && !order_items.length"
        title="Empty order items"
      />
    </div>

  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';

export default {
    components:{
        VeTable,
        Spinner,
        EmptyState,
    },
    props:{
        order_items:{
            type: Array,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        cols: {
            type: Array,
            default: () => [],
        },
        merchant: {
          type: Object,
          default: {},
        },
        order: {
          type: Object,
          default: {},
        },

    },
    data(){
        return {
          sortConfig: {},
          sortOption: {
            sortAlways: true,
            sortChange: params => this.$emit('on-sort-change', params),
          },
        }
    },
    computed:{
        tableData(){
            console.log(this.cols,this.order_items);
            if (this.isLoading || !this.order_items?.length) {
                return [];
            }

            const formattedOrders = this.order_items.map(item=>{
                const preSet = {
                  ...item,
                  total:parseFloat(parseFloat(item.item_price) * (parseInt(item.quantity)||0).toFixed(2))
                }
                return preSet;
            })

            // calculate total, sst, grantTotal here
            const sumTotal = formattedOrders.reduce((acc, item) => acc + parseFloat(item.item_price) * (parseInt(item.quantity)||0), 0);

            const sst = this.merchant.sst === 'true' ? parseFloat(sumTotal * 0.08) : 0;
            // const sst = parseFloat(sumTotal * 0.08);
            const grantTotal = sumTotal + sst;

            const sumRows = [
              {
                item_price: "Total",                        // at 'item_price' column display text 'Total'
                total: parseFloat(sumTotal.toFixed(2))      // at 'total' column display sum amount
              },
              {
                item_price: "SST",                          // at 'item_price' column display text 'SST'
                total: parseFloat(sst.toFixed(2))           // at 'total' column display sst amount
              },
              {
                item_price: "Grant Total",                        // at 'item_price' column display text 'Grand Total'
                total: parseFloat(grantTotal.toFixed(2))          // at 'total' column display grantTotal amount
              },

            ]
            return [
              ...formattedOrders,
              ...sumRows,
            ]
        },
        columns(){
            let matches = {};

            matches.item_name = {
                field: 'item_name',
                key: 'item_name',
                title: 'Item Name',
                fixed: 'left',
                align: 'left',
                width: 240,
                sortBy: this.sortConfig.item_name || '',
                renderBodyCell: ({row})=>{
                    return <span>{row.item_name}</span>
                }
            }
            matches.currency = {
                field: 'currency',
                key: 'currency',
                title: 'Currency',
                align: 'center',
                sortBy: this.sortConfig.currency || '',
                renderBodyCell: ({row})=>{
                    return <span>{row.currency}</span>
                }
            }
            matches.item_price = {
                field: 'item_price',
                key: 'item_price',
                title: 'Price',
                align: 'right',
                sortBy: this.sortConfig.item_price || '',
                renderBodyCell: ({row})=>{
                    return <span>{row.item_price}</span>
                }
            }
            matches.quantity = {
                field: 'quantity',
                key: 'quantity',
                title: 'Quantity',
                align: 'right',
                sortBy: this.sortConfig.quantity || '',
                renderBodyCell: ({row})=>{
                    // return <span>{row.quantity}</span>
                    if (typeof row.quantity !== "number") {
                      return <span></span>
                    }
                    return <div>
                      <input
                        type="number"
                        value={row.quantity}
                        onKeydown={(e) => this.handleQuantity(e, row)}
                        onKeyup={(e) => this.handleQuantity(e, row)}
                      />
                    </div>
                }
            }
            matches.total = {
                field: 'total',
                key: 'total',
                title: 'Total',
                align: 'right',
                sortBy: this.sortConfig.total || '',
                renderBodyCell: ({row})=>{
                    return <span>{row.total}</span>
                }
            }
            matches.action = {
                field: 'action',
                key: 'action',
                title: 'Action',
                align: 'center',
                renderBodyCell: ({row})=>{
                  if (typeof row.id != "number") {
                    return <span></span>
                  }
                  
                  const enableStatuslList = ['fresh','pending','packed','paid'];
                  const isDisable = !enableStatuslList.includes(this.order?.order_status?.id);

                    return (
                    <div>
                      <woot-button
                        variant="smooth"
                        size="small"
                        color-scheme="alert"
                        icon="delete"
                        disabled={isDisable}
                        onClick={()=>this.deleteItem(row)}
                      >
                      </woot-button>
                    </div>
                    )
                }
            }







            const preSet = [];
            this.cols.forEach(col=>{
                if (col.visible) {
                    if (col.key in matches) {
                        preSet.push(matches[col.key])
                    }
                }
            })
            return preSet;
        },
        // end computed
    },

    methods:{
      handleQuantity(e,rowData){
        this.$emit('onQuantityChange',e,rowData)
        /*
          const quantity = parseInt(e.target.value||"0");
          const index = this.order_items.findIndex(item=>item.id ===rowData.id)
          console.log({index});
          if (index > -1) {
            this.order_items[index].quantity = quantity
          }
        */
      },
      deleteItem(row){
        const index = this.order_items.findIndex(item => item.id === row.id);
        if (index > -1) {
            this.order_items.splice(index, 1);
        }
      },
      // end methods
    },

}
</script>




<style lang="scss" scoped>
@import '~dashboard/assets/scss/mixins';

.orderItems_table_wrap {
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.orderItems_table_wrap::v-deep {
  .ve-table {
    padding-bottom: var(--space-large);
  }

  .ve-table-header-th {
    padding: var(--space-small) var(--space-two) !important;
    background-color: var(--w-500) !important;
    color: var(--white) !important;
  }

  .ve-table-body-td {
    padding: var(--space-small) var(--space-two) !important;
  }

  .ve-table-header-th {
    font-size: var(--font-size-mini) !important;
  }
  .ve-table-sort {
    top: -4px;
  }

}

.loader_and_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-default);
  padding: var(--space-big);
}

</style>